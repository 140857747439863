<template>
  <component :is="item === undefined ? 'div' : 'b-card'">
    <b-overlay
      :show="item === null"
      rounded="sm"
    >
      <div v-if="item">
        <b-form>
          <b-row>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                :label="$t('form.title.label')"
                label-for="1c_id"
              >
                <b-form-input
                  id="1c_id"
                  v-model="item.title"
                  :state="errors && errors.title ? false : null"
                />

                <b-form-invalid-feedback v-if="errors && errors.title">
                  {{ errors.title[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                :label="$t('form.code.label')"
                label-for="code"
              >
                <b-form-input
                  id="code"
                  v-model="item.code"
                  :state="errors && errors.code ? false : null"
                />

                <b-form-invalid-feedback v-if="errors && errors.code">
                  {{ errors.code[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                :label="$t('form.symbol_before.label')"
                label-for="symbol_before"
              >
                <b-form-input
                  id="symbol_before"
                  v-model="item.symbol_before"
                  :state="errors && errors.symbol_before ? false : null"
                />

                <b-form-invalid-feedback v-if="errors && errors.symbol_before">
                  {{ errors.symbol_before[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                :label="$t('form.symbol_after.label')"
                label-for="symbol_after"
              >
                <b-form-input
                  id="symbol_after"
                  v-model="item.symbol_after"
                  :state="errors && errors.symbol_after ? false : null"
                />

                <b-form-invalid-feedback v-if="errors && errors.symbol_after">
                  {{ errors.symbol_after[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="12"
            >
              <b-form-group
                :label="$t('form.value.label')"
                label-for="value"
              >
                <b-form-input
                  id="value"
                  v-model="item.value"
                  :state="errors && errors.value ? false : null"
                />

                <b-form-invalid-feedback v-if="errors && errors.value">
                  {{ errors.value[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>

          <!-- Action Buttons -->
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="onSubmit"
          >
            {{ $t('general.save') }}
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="outline-secondary"
            :to="{ name: 'currencies-index' }"
          >
            {{ $t('general.cancel') }}
          </b-button>
        </b-form>
      </div>
    </b-overlay>
  </component>
</template>

<script>
import GlobalMixin from '@/mixins/GlobalMixin'

import router from '@/router'
import ToastificationContent from '@core/components/toastification/ToastificationContent'

export default {
  mixins: [GlobalMixin],
  data() {
    return {
      item: null,
    }
  },
  async beforeCreate() {
    await this.$http.get(`/api/currencies/${router.currentRoute.params.id}`)
      .then(response => {
        const { data } = response.data

        this.item = data
      })
      .catch(error => {
        if (error.response.status === 404) {
          this.item = undefined
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('notifications.not_found'),
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
          router.replace({ name: 'currencies-index' })
        }
      })
  },
  methods: {
    onSubmit() {
      const data = {
        code: this.item.code,
        title: this.item.title,
        value: this.item.value,
        symbol_before: this.item.symbol_before,
        symbol_after: this.item.symbol_after,
      }

      this.$http.put(`/api/currencies/${this.item.id}`, data)
        .then(() => {
          router.replace({ name: 'currencies-index' })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: error.response.data.message,
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
          this.$refs.roleForm.setErrors(error.response.data.errors)
        })
    },
    transformData(item) {
      return {
        id: item.id,
        code: item.code,
        title: item.title,
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
